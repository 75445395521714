<template>
  <div>

    <div class="mb-3" v-show="!hide_select_person">
      <label class="robo-14-400 txt-grey-600 mb-2">Tên bệnh nhân</label>
      <v-select
        v-if="persons && persons.data && can('view-patient-list')"
        class="txt-black bg-white border radius-10 border-2"
        :options="persons.data"
        label="name"
        placeholder="Chọn bệnh nhân"
        :filterable="false"
        @open="onOpen"
        @close="onClose"
        v-model="selected_person"
        transition=""
        @search="searchPerson"
        @input="selectedPerson"
      >
        <div slot="no-options">Not found!</div>
        <template v-slot:option="item">
          <span class="font-weight-bold">{{item.name}} - {{item.phone}}</span>
        </template>
        <template #list-footer>
          <li
            ref="load"
            v-show="hasNextPage"
          ></li>
        </template>
      </v-select>
      <div v-if="!can('view-patient-list')">
        <p class="mb-0 robo-16-500 text-red">Bạn không có quyền xem danh sách bệnh nhân</p>
      </div>
    </div>
    <div class="mb-3">
      <label class="robo-14-400 txt-grey-600 mb-2">Hồ Sơ Sức Khỏe</label>
      <v-select
        class="txt-black bg-white border radius-10 border-2"
        :options="diseases.data"
        label="diseases_name"
        placeholder="Hồ Sơ Sức Khỏe"
        :filterable="false"
        @open="onOpenDisease"
        @close="onCloseDisease"
        v-model="slt_disease"
        transition=""
        @search="searchDisease"
        @input="selectedDisease"
      >
        <div slot="no-options">Chưa có hồ sơ nào!</div>
        <template #list-footer>
          <li
            ref="load"
            v-show="diseaseHasNextPage"
          ></li>
          <div
            class="d-flex align-items-center justify-content-center"
            v-if="loading_disease"
          >
            <div
              class="spinner-border mt-5"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: 'SelectDisease',
  props: ['refresh_disease', 'refresh_person', 'person', 'hide_select_person'],
  components: {vSelect},
  data () {
    return {
      observer: null,
      observerDisease: null,
      diseases: [],
      persons: [],
      selected_person: null,
      loading_disease: false,
      loading: false,
      search_person: '',
      slt_disease: null
    }
  },
  watch: {
    refresh_disease (c) {
      if (c) {
        this.slt_disease = null
        this.diseases = []
        this.getDiseases(20)
      }
    },
    refresh_person (r) {
      this.selected_person = null
      this.search_person = ''
      this.getPersons(20)
    },
    person (p) {
      this.selected_person = p
      this.diseases = []
      this.getDiseases(20)
    }
  },
  mounted () {
    let self = this
    self.observer = new IntersectionObserver(self.infiniteScroll)
    self.observerDisease = new IntersectionObserver(self.infiniteScrollDisease)
    self.getDiseases(20)
    self.getPersons(20)
  },
  methods: {
    async getPersons (num) {
      let self = this
      let clinic_id = this.$clinicId
      if (!clinic_id) return
      try {
        self.loading = !num
        let params = {
          limit: num || 50,
          sort: 'created_at',
          sort_by: 'desc',
          search: self.search_person
        }
        await self.$rf.getRequest('DoctorRequest').getPersonsOfWorkspace(clinic_id, params).then(res => {
          self.persons = res.data
        })
        return true
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    selectedPerson (p) {
      this.getDiseases(20)
      this.$emit('selectedPerson', p)
      this.slt_disease = null
    },
    hasNextPage () {
      let self = this
      if (self.persons.total > self.persons.count) {
        return true
      }
      return false
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    searchPerson (search) {
      let self = this
      if (search) {
        self.onSearhPerson(search)
      } else {
        self.onSearchPersonDelete()
      }
    },
    onSearhPerson (search) {
      let self = this
      self.search_person = search
      self.getPersons()
    },
    onSearchPersonDelete () {
      let self = this
      self.search_person = ''
      self.getPersons()
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.persons.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.$emit('onRefresh', params.limit)
      }
    },
    diseaseHasNextPage () {
      let self = this
      if (self.diseases.total > self.diseases.count) {
        return true
      }
      return false
    },
    async onOpenDisease () {
      if (this.diseaseHasNextPage) {
        await this.$nextTick()
        this.observerDisease.observe(this.$refs.load)
      }
    },
    onCloseDisease () {
      this.observerDisease.disconnect()
    },
    searchDisease (search) {
      this.getDiseases(20, search)
    },
    async infiniteScrollDisease ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.diseases.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.$emit('onRefresh', params.limit)
      }
    },
    async getDiseases (limit, search) {
      let self = this
      if (!this.$clinicId) return
      self.loading_disease = true
      let params = {
        limit,
        sort: 'updated_at',
        sort_by: 'desc',
        is_accepted: 1,
        clinic_id: this.$clinicId
      }
      if (self.selected_person) {
        params.person_id = self.selected_person.id
      }
      if (self.person) {
        params.person_id = self.person.id
      }
      if (search) {
        params.search = search
      }
      await self.$rf.getRequest('DoctorRequest').getPersonDiseases(params).then(res => {
        self.diseases = res.data
      }).finally(() => {
        self.loading_disease = false
      })
    },
    selectedDisease (d) {
      this.$emit('selectedDisease', d)
    }
  }
}
</script>

<style scoped>
</style>